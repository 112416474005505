import { createSimpleStore } from '~/utils/pinia/light-simple-store'

export const useProfileDataStore = createSimpleStore('profile-data', {
  getRequests: async () => {
    const { $sdk } = useNuxtApp()
    const { getProfile, updateProfile, removeProfile } =
      await $sdk.module('profile')

    return {
      get: getProfile,
      update: updateProfile,
      remove: removeProfile
    }
  }
})
